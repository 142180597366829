import React from "react"
import Layout from "../components/Layout"
import Container from "../components/Container"
import PageTitle from "../components/PageTitle"
import { FAQJsonLd } from "gatsby-plugin-next-seo"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import { graphql, StaticQuery } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import casinolistStyle from "../components/Casinolist.module.css"

class Kasinot extends React.Component {
  setImages() {
    var talletus = document.getElementsByClassName("talletus")
    var o
    for (o = 0; o < talletus.length; o++) {
      var trustly =
        "<img src='/images/maksu/trustly.webp' width='75' height='50' title='Trustly' alt='trustly' class='lazyload'/>"
      var perinteiset =
        "<img src='/images/maksu/perinteiset.webp' width='75' height='50' title='Perinteiset maksutavat' alt='perinteiset' class='lazyload'/>"
      var brite =
        "<img src='/images/maksu/brite.webp' width='75' height='50' alt='brite' title='Brite' class='lazyload'/>"
      var zimpler =
        "<img src='/images/maksu/zimpler.webp' width='75' height='50' title='Zimpler' alt='zimpler' class='lazyload'/>"
      var str = talletus[o].innerHTML

      var res = str
        .replace("Trustly", trustly)
        .replace("Brite", brite)
        .replace("Perinteiset", perinteiset)
        .replace("Zimpler", zimpler)
      talletus[o].innerHTML = res
    }

    var lisenssi = document.getElementsByClassName("lisenssi")
    var o
    for (o = 0; o < lisenssi.length; o++) {
      var malta =
        "<img src='/images/lisenssi/mga.webp' width='75' height='75' title='Maltan lisenssi' alt='Maltan lisenssi' class='lazyload'/>"
      var viro =
        "<img src='/images/lisenssi/maksu-tolliamet.webp' width='75' height='75' title='Viron lisenssi' alt='Viron lisenssi' class='lazyload'/>"
      var curacao =
        "<img src='/images/lisenssi/curacao.webp' width='75' height='75' title='Curacaon lisenssi' alt='Curacaon lisenssi' class='lazyload'/>"
      var antillephone =
        "<img src='/images/lisenssi/antillephone.webp' width='75' height='75' title='Curacaon lisenssi / Antillephone' alt='Curacaon lisenssi / Antillephone' class='lazyload'/>"
      var str = lisenssi[o].innerHTML

      var res = str
        .replace("Malta", malta)
        .replace("Viro", viro)
        .replace("Curacao", curacao)
        .replace("Antillephone", antillephone)
      lisenssi[o].innerHTML = res
    }
  }

  componentDidMount() {
    var links = document.links
    for (var i = 0, linksLength = links.length; i < linksLength; i++) {
      if (links[i].hostname != window.location.hostname) {
        links[i].target = "_blank"
        links[i].rel = "noopener"
      }
    }
    this.setImages()
  }

  componentDidUpdate() {
    this.setImages()
  }

  //LOAD MORE
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      visible: 20,
      error: false
    }
    this.loadMore = this.loadMore.bind(this)
    this.showLess = this.showLess.bind(this)
  }
  loadMore() {
    this.setState(prev => {
      return { visible: prev.visible + 20 }
    })
  }
  showLess() {
    this.setState(prev => {
      return { visible: 20 }
    })
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            contentfulMoneysivujenOsiot(slug: { eq: "kasinot" }) {
              slug
              sivu
              ylosanTeksti {
                json
              }
              kasinolista {
                kasinonNimi
                kasinonListanimi
                lause1
                ilmaiskierrokset
                rahansiirto
                lisenssi
                logonTiedostonimi
                afflink
                kasinonLisatiedot {
                  json
                }
              }
              alaosanTeksti {
                json
              }
              alaosanTeksti2 {
                json
              }
            }
          }
        `}
        render={data => (
          <Layout>
            <GatsbySeo
              title="Uusi Kasino 2025 | Uudet Nettikasinot: Valitse paras!"
              description="Vuoden 2025 uusimmat kasinot, kerättynä nyt samalle sivulle. Aina ajantasalla. Uusi nettikasino, uusi mahdollisuuus!"
            />
            <FAQJsonLd
              questions={[
                {
                  question: "Onko jokainen uusi nettikasino luotettava?",
                  answer:
                    "Lähtökohtaisesti kaikki uudet nettikasinot ovat läpikäyneet seulontaprosessin jonkin valvovan viranomaisen toimesta. Joukkoon saattaa kuitenkin eksyä aina silloin tällöin myös hämäräperäisiä toimijoita. Uusikasino.com :n kautta löytyvät nettikasinot ovat 100% tarkistettuja ja luotettavia."
                },
                {
                  question:
                    "Voinko avata asiakastilin jokaiselle uudelle kasinolle?",
                  answer:
                    "Kyllä. Asiakastilien määrää eri kasinoille ei ole mitenkään rajoitettu. Muista kuitenkin että jos olet asettanut omaehtoisen pelieston tietylle kasinolle, ja tämän kasinon omistava yhtiö avaa uuden kasinon, et voi avata pelitiliä kyseiselle sivustolle."
                },
                {
                  question:
                    "Kuinka monta uutta kasinoa on tulossa vuonna 2025?",
                  answer:
                    "Tulevien kasinoiden tarkkaa määrää on vaikea arvioida etukäteen, mutta ennustamme että sivustomme seulan läpäisee ja listaukseen pääsee tänä vuonna vähintään 50-60 nettikasinoa."
                },
                {
                  question:
                    "Mikä on yleisin käytössä oleva maksutapa uusilla kasinoilla?",
                  answer:
                    "Trustlya, Britea ja Zimpleria käyttävät nk. pikakasinot ovat yleistyneet viime vuosina hurjasti. Tänäkin vuonna edellämainitut tulevat olemaan yleisimmät uusilta kasinoilta löytyvät maksunvälittäjät."
                },
                {
                  question:
                    "Olen tyytyväinen nykyiseen pelipaikkaani. Miksi vaihtaisin kasinoa?",
                  answer:
                    "Hienoa! Tässä tapauksessa vaihtaminen ei välttämättä ole tarpeellista. Moni valitsee uuden kasinon hyödyntääkseen uusille pelaajille tarjotut bonusedut tai hakeakseen vaihtelua."
                },
                {
                  question:
                    "Voitin! Mutta kotiutukseni on jumissa, voitteko auttaa?",
                  answer:
                    "Rahojen kotiutus voi olla jumissa monesta eri syystä. Yleisin syy on puutteelliset tai virheelliset henkilöllisyyden todistavat dokumentit. Ensimmäinen askel tilanteen selvittämiseksi on ottaa suora yhteys kyseisen nettikasinon asiakaspalveluun."
                }
              ]}
            />
            <Container>
              <div class="suodatin">
                <p>
                  <b>Suodata uudet nettikasinot -listaa:</b>
                </p>
                <a class="nappi" href="/" target="_self">
                  Uusi Kasino
                </a>{" "}
                <a class="nappi" href="/malta/" target="_self">
                  Malta
                </a>{" "}
                <a class="nappi" href="/viro/" target="_self">
                  Viro
                </a>{" "}
                <a
                  class="nappi"
                  href="/ilman-rekisteroitymista/"
                  target="_self"
                >
                  Ilman rekisteröitymistä
                </a>
                <p>
                  <b>Selaat nyt:</b>
                </p>
                <p>
                  <i>Kaikki uudet kasinot 2025</i>
                </p>
              </div>
              <table>
                <tbody>
                  {data.contentfulMoneysivujenOsiot.kasinolista
                    .slice(0, this.state.visible)
                    .map(
                      ({
                        logonTiedostonimi,
                        kasinonListanimi,
                        lause1,
                        ilmaiskierrokset,
                        afflink,
                        kasinonLisatiedot,
                        rahansiirto,
                        lisenssi
                      }) => (
                        <tr className={casinolistStyle.casino}>
                          <td className={casinolistStyle.top}>
                            <div className={casinolistStyle.logo}>
                              <img
                                data-src={`/images/a/${logonTiedostonimi}`}
                                alt={kasinonListanimi}
                                title={kasinonListanimi}
                                className="lazyload"
                                width="120"
                                height="50"
                              />
                              <div className={casinolistStyle.name}>
                                <h5 className={casinolistStyle.title}>
                                  &#10003; {kasinonListanimi}
                                </h5>
                              </div>
                            </div>
                            <div className={casinolistStyle.content}>
                              <div className={casinolistStyle.contentBox}>
                                <div className={casinolistStyle.contentInfo}>
                                  <div className={casinolistStyle.offerItem}>
                                    <p>&#11088; {lause1}</p>
                                    <p>&#11088; {ilmaiskierrokset}</p>
                                  </div>
                                </div>
                                <div className={casinolistStyle.contentBtn}>
                                  <a
                                    className={casinolistStyle.btn}
                                    href={afflink}
                                    target="_blank"
                                  >
                                    &#9889; Pelaa &#9889;
                                  </a>
                                  <div className="talletus">{rahansiirto}</div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className={casinolistStyle.bottom}>
                            <div className={casinolistStyle.casinoalaosa}>
                              <div className="lisenssi">{lisenssi}</div>
                              {documentToReactComponents(
                                kasinonLisatiedot.json
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
              <div className="loadBtn">
                {this.state.visible > 20 && (
                  <button onClick={this.showLess} type="button" id="showLess">
                    Vähemmän
                  </button>
                )}
                {this.state.visible <
                  data.contentfulMoneysivujenOsiot.kasinolista.length && (
                  <button onClick={this.loadMore} type="button" id="loadMore">
                    Näytä lisää
                  </button>
                )}
              </div>
              <div className="ylaosa">
                {documentToReactComponents(
                  data.contentfulMoneysivujenOsiot.ylosanTeksti.json,
                  {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ASSET]: node => (
                        <img
                          className="lazyload page-img"
                          data-src={`${node.data.target.fields.file["en-US"].url}`}
                          alt={node.data.target.fields.title["en-US"]}
                        />
                      )
                    }
                  }
                )}
              </div>
              <PageTitle>{data.contentfulMoneysivujenOsiot.sivu}</PageTitle>
              <div className="alaosa">
                {documentToReactComponents(
                  data.contentfulMoneysivujenOsiot.alaosanTeksti.json,
                  {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ASSET]: node => (
                        <img
                          className="lazyload page-img"
                          data-src={`${node.data.target.fields.file["en-US"].url}`}
                          alt={node.data.target.fields.title["en-US"]}
                        />
                      )
                    }
                  }
                )}
              </div>
              <div className="alaosa2">
                {documentToReactComponents(
                  data.contentfulMoneysivujenOsiot.alaosanTeksti2.json,
                  {
                    renderNode: {
                      [BLOCKS.EMBEDDED_ASSET]: node => (
                        <img
                          className="lazyload page-img"
                          data-src={`${node.data.target.fields.file["en-US"].url}`}
                          alt={node.data.target.fields.title["en-US"]}
                        />
                      )
                    }
                  }
                )}
              </div>
              <div class="suodatin3">
                <center>
                  <h2>FAQ - Kysyttyä</h2>
                </center>
                <p>
                  Kasinoiden kanssa touhutessa saattaa ajautua
                  ongelmatilanteisiin. Olemme koonneet alle yleisimmät meiltä
                  kysytyt kysymykset ja niihin vastaukset.
                </p>
                <div>
                  <div>
                    <h5>
                      &#128204; Onko jokainen uusi nettikasino luotettava?{" "}
                    </h5>
                  </div>
                  <p>
                    Lähtökohtaisesti kaikki uudet nettikasinot ovat läpikäyneet
                    seulontaprosessin jonkin valvovan viranomaisen toimesta.
                    Joukkoon saattaa kuitenkin eksyä aina silloin tällöin myös
                    hämäräperäisiä toimijoita. Uusikasino.com :n kautta löytyvät
                    nettikasinot ovat 100% tarkistettuja ja luotettavia.
                  </p>
                </div>
                <div>
                  <div>
                    <h5>
                      &#128204; Voinko avata asiakastilin jokaiselle uudelle
                      kasinolle?{" "}
                    </h5>
                  </div>
                  <p>
                    Kyllä. Asiakastilien määrää eri kasinoille ei ole mitenkään
                    rajoitettu. Muista kuitenkin että jos olet asettanut
                    omaehtoisen pelieston tietylle kasinolle, ja tämän kasinon
                    omistava yhtiö avaa uuden kasinon, et voi avata pelitiliä
                    kyseiselle sivustolle.
                  </p>
                </div>
                <div>
                  <div>
                    <h5>
                      &#128204; Kuinka monta uutta kasinoa on tulossa vuonna
                      2025?
                    </h5>
                  </div>
                  <p>
                    Tulevien kasinoiden tarkkaa määrää on vaikea arvioida
                    etukäteen, mutta ennustamme että sivustomme seulan läpäisee
                    ja listaukseen pääsee tänä vuonna vähintään 50-60
                    nettikasinoa.
                  </p>
                </div>
                <div>
                  <div>
                    <h5>
                      &#128204; Mikä on yleisin käytössä oleva maksutapa uusilla
                      kasinoilla?{" "}
                    </h5>
                  </div>
                  <p>
                    Trustlya, Britea ja Zimpleria käyttävät nk. pikakasinot ovat
                    yleistyneet viime vuosina hurjasti. Tänäkin vuonna
                    edellämainitut tulevat olemaan yleisimmät uusilta kasinoilta
                    löytyvät maksunvälittäjät.
                  </p>
                </div>
                <div>
                  <div>
                    <h5>
                      &#128204; Olen tyytyväinen nykyiseen pelipaikkaani. Miksi
                      vaihtaisin kasinoa?{" "}
                    </h5>
                  </div>
                  <p>
                    Hienoa! Tässä tapauksessa vaihtaminen ei välttämättä ole
                    tarpeellista. Moni valitsee uuden kasinon hyödyntääkseen
                    uusille pelaajille tarjotut bonusedut tai hakeakseen
                    vaihtelua.
                  </p>
                </div>
                <div>
                  <div>
                    <h5>
                      &#128204; Voitin! Mutta kotiutukseni on jumissa, voitteko
                      auttaa?{" "}
                    </h5>
                  </div>
                  <p>
                    Rahojen kotiutus voi olla jumissa monesta eri syystä.
                    Yleisin syy on puutteelliset tai virheelliset
                    henkilöllisyyden todistavat dokumentit. Ensimmäinen askel
                    tilanteen selvittämiseksi on ottaa suora yhteys kyseisen
                    nettikasinon asiakaspalveluun.
                  </p>
                </div>
              </div>
            </Container>
          </Layout>
        )}
      />
    )
  }
}

export default Kasinot
